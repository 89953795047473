<script setup lang="ts">
const props = defineProps<{
  delayEnter?: number
  delayLeave?: number
}>()
const wrapperRef = ref()

const isHover = useElementHover(wrapperRef, {
  delayLeave: props.delayLeave || 0,
  delayEnter: props.delayEnter || 0,
})
</script>

<template>
  <div ref="wrapperRef">
    <slot :isHovered="isHover" />
  </div>
</template>
