<template>
  <Menu as="div" class="relative inline-flex text-left" v-slot="slotProps">
    <slot name="button" />
    <slot v-bind="slotProps" />
  </Menu>
</template>

<script setup lang="ts">
const { Menu } = await import('@headlessui/vue')
</script>
